import moment from 'moment';
import momentTimezone from 'moment-timezone';
import dayjs from 'dayjs';

const config = {
  0: '周日',
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  7: '周日',
};

/** 时间差对比类型 */
export const TimeDiffType = {
  RELATIVE: 1,
  ABSOLUTE: 2,
};

/** 年、月、日、时、分的毫秒数 */
export const YearMsec = 365 * 24 * 60 * 60 * 1000;
export const MonthMsec = 30 * 24 * 60 * 60 * 1000;
export const WeekMsec = 7 * 24 * 60 * 60 * 1000;
export const DayMsec = 24 * 60 * 60 * 1000;
export const HourMsec = 60 * 60 * 1000;
export const MinuteMsec = 60 * 1000;
export const secondMsec = 1 * 1000;

/**
 * @name initTime 时间初始化
 */
export const initTime = (time) => {
  if (typeof time === 'number' && String(time).length == 10) {
    // 兼容秒为单位的时间格式
    time *= 1000;
  }
  return time;
};

export const getHourAndMin = (timestamp) => moment(timestamp).format('HH:mm');

export const fillZero = (str) => {
  str = String(str);
  return str.toString().length === 1 ? `0${str}` : str;
};

/**
 * 格式化时间戳
 */
export const formatTimestamp = (time, timeFormat) => {
  if (!time) return '-';
  time = initTime(time);
  timeFormat = timeFormat || 'YYYY-MM-DD HH:mm:ss';
  return moment(time).format(timeFormat);
};

/**
 * 根据日期返回格式化的字符串 2010-01-01
 */
export const getTimeStr = (time, split = '-') => {
  time = time || new Date();

  let month = time.getMonth() + 1;
  month = fillZero(month);
  let day = time.getDate();
  day = fillZero(day);

  return time.getFullYear() + split + month + split + day;
};

/**
 * 根据日期返回格式化的字符串 2010-01
 */
export const getMonthStr = (time, split) => {
  time = time || new Date();
  split = split || '-';

  let month = time.getMonth() + 1;
  month = fillZero(month);

  return time.getFullYear() + split + month;
};

export const getPreMonthStr = (time, split) => {
  time = time || new Date();
  split = split || '-';

  let month = time.getMonth();

  if (month == 0) {
    month = 12;
    return time.getFullYear() - 1 + split + month;
  }

  month = fillZero(month);

  return time.getFullYear() + split + month;
};

/**
 * 根据日期返回格式化的完整字符串 2010-01-01 00:00:00
 */
export const getFullTime = (time, split) => {
  time = time || new Date();
  split = split || '-';

  const year = time.getFullYear();
  let month = time.getMonth() + 1;
  month = fillZero(month);
  let day = time.getDate();
  day = fillZero(day);
  let hour = time.getHours();
  hour = fillZero(hour);
  let min = time.getMinutes();
  min = fillZero(min);
  let sec = time.getSeconds();
  sec = fillZero(sec);
  return `${year}${split}${month}${split}${day} ${hour}:${min}:${sec}`;
};

/**
 * 返回当前日期几天后的时间
 */
export const getRecentDate = (days) => {
  days = typeof days === 'undefined' ? 0 : window.parseInt(days, 10);
  const time = new Date();
  time.setDate(time.getDate() + days);

  return time;
};

/**
 * 返回格式化的当前日期几天后的时间
 */
export const getFormatRecentDate = (days) => getTimeStr(getRecentDate(days));

/**
 * 获取当前时间， 以后每半小时为间隔的所有时间
 */
export const getIntervalTime = (time) => {
  time = time || new Date();
  const hour = time.getHours();
  const min = time.getMinutes();
  const timeArr = [];

  if (min === 0) {
    timeArr.push('00:00');
  }

  if (min <= 30) {
    timeArr.push(`${fillZero(hour)}:30`);
  }

  for (let i = hour + 1; i < 24; i++) {
    timeArr.push(`${fillZero(i)}:00`);
    timeArr.push(`${fillZero(i)}:30`);
  }

  return timeArr;
};

/**
 * 获取所有以30为间隔的时间
 */
export const getAllIntervalTime = () => {
  const timeArr = [];

  for (let i = 0; i < 24; i++) {
    timeArr.push(`${fillZero(i)}:00`);
    timeArr.push(`${fillZero(i)}:30`);
  }

  return timeArr;
};

/**
 * 获取指定时间之间的以30为间隔的时间
 * start  : m
 * end    : m
 */

export const getPointIntervalTime = (start = 0, end = 23.5 * 60, interval = 30) => {
  const timeArr = [];

  for (let i = start; i <= end; i += interval) {
    const block = Math.floor(i / 60);
    const isToday = block >= 24;

    const dayText = isToday ? '次日 ' : '';
    const hourText = fillZero(isToday ? block - 24 : block);
    const minuteText = i % 60 === 0 ? '00' : `${i % 60}`;

    timeArr.push({
      value: i,
      text: `${dayText}${hourText}:${minuteText}`,
    });
  }
  return timeArr;
};

export const getTimeTextFromTimeNumber = (number) => {
  const block = Math.floor(number / 60);
  const isToday = block >= 24;

  const dayText = isToday ? '次日 ' : '';
  const hourText = fillZero(isToday ? block - 24 : block);
  const minuteText = number % 60 === 0 ? '00' : '30';

  return `${dayText}${hourText}:${minuteText}`;
};

export const getTimeNumberFromTimeText = (text) => {
  const time = text.split(':');

  if (time.length === 0) {
    return 0;
  }

  let hour = time[0];
  const min = time[1];

  if (hour.indexOf('次日') >= 0) {
    hour = 24 + parseInt(hour.split(' ')[1], 10);
  }

  return 60 * hour + parseInt(min, 10);
};

function withZero(time) {
  return time < 10 ? "0" + time : time;
}

export const diffRelativeDateTime = (target = new Date(), current = new Date()) => {
  const now = moment(current).toDate();
  const end = moment(target).toDate();
  const sourceDiff = end.getTime() - now.getTime();
  const diff = Math.abs(sourceDiff);

  const year = Math.floor(diff / YearMsec);
  const month = Math.floor((diff - year * YearMsec) / MonthMsec);
  const week = Math.floor((diff - year * YearMsec - month * MonthMsec) / WeekMsec);
  const day = Math.floor((diff - year * YearMsec - month * MonthMsec) / DayMsec);
  const hour = Math.floor((diff - year * YearMsec - month * MonthMsec - day * DayMsec) / HourMsec);
  const min = Math.floor((diff - year * YearMsec - month * MonthMsec - day * DayMsec - hour * HourMsec) / MinuteMsec);

  return {
    year,
    month,
    week,
    day,
    hour,
    min,
    diff: diff !== 0,
  };
};

/**获取还剩下多少时间 */
export const diffRelativeDateTimeWithDay = (target = new Date(), current = new Date()) => {
  const now = moment(current).toDate();
  const end = moment(target).toDate();
  const sourceDiff = end.getTime() - now.getTime();
  const diff = Math.abs(sourceDiff);
  if (sourceDiff <= 0) {
    return {
      day: 0,
      hour: withZero(hour),
      min: withZero(min),
      second: withZero(second),
      diff: diff !== 0,
    };
  }
  const day = Math.floor(diff / DayMsec);
  const hour = Math.floor((diff - day * DayMsec) / HourMsec);
  const min = Math.floor((diff - day * DayMsec - hour * HourMsec) / MinuteMsec);
  const second = Math.floor((diff - day * DayMsec - hour * HourMsec - min * MinuteMsec) / secondMsec);

  return {
    day,
    hour,
    min,
    second,
    diff: diff !== 0,
  };
};

export const diffAbsoluteDateTime = (target = new Date(), current = new Date()) => {
  const now = moment(current).toDate();
  const end = moment(target).toDate();
  const sourceDiff = end.getTime() - now.getTime();
  const diff = Math.abs(sourceDiff);

  const year = Math.floor(diff / YearMsec);
  const month = Math.floor(diff / MonthMsec);
  const week = Math.floor(diff / WeekMsec);
  const day = Math.floor(diff / DayMsec);
  const hour = Math.floor(diff / HourMsec);
  const min = Math.floor(diff / MinuteMsec);

  return {
    year,
    month,
    week,
    day,
    hour,
    min,
    diff: diff !== 0,
  };
};

/**
 * 计算两个日期之间的时间差
 */
export const diffDateTime = (target = new Date(), current = new Date(), type = TimeDiffType.RELATIVE) => {
  switch (type) {
    case TimeDiffType.RELATIVE:
      return diffRelativeDateTime(target, current);
    case TimeDiffType.ABSOLUTE:
    default:
      return diffAbsoluteDateTime(target, current);
  }
};

/**
 * 格式化后的两个日期时间差
 */
export const formatDiffDateTime = (target) => {
  const { year, month, day, hour, min, diff } = diffDateTime(target);

  const yearStr = year !== 0 ? `${year}年` : '';
  const monthStr = month !== 0 ? `${month}月` : '';
  const dayStr = day !== 0 ? `${day}天` : '';
  const hourStr = hour !== 0 ? `${hour}小时` : '';
  const minStr = min !== 0 ? `${min}分钟` : '';

  const diffType = diff ? '剩余' : '超时';

  if (diffType === '超时') {
    if (year) {
      return `超时${year * 365 + month * 30 + day}天`;
    }

    if (month) {
      return `超时${year * 365 + month * 30 + day}天`;
    }

    if (day) {
      return `超时${day}天`;
    }

    if (hour) {
      return `超时${hour}小时`;
    }
  }

  return diffType + yearStr + monthStr + dayStr + hourStr + minStr;
};

/**
 * 获取本月第一天
 */
export const getFirstDayOfMonth = (year, month) => {
  const now = new Date();
  year = year || now.getFullYear();
  month = month || now.getMonth() + 1;
  month = fillZero(month);
  return `${year}-${month}-01`;
};
/**
 * 获取当前时间所在月的第一天
 */
export const getFirstDayOfMonthByTime = (timestamp = new Date().getTime()) => {
  timestamp = initTime(timestamp);
  const now = new Date(timestamp);
  now.setDate(1);
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  return now.getTime();
};

/**
 * 获取当前时间所在月的最后一天
 */
export const getLastDayOfMonthByTime = (timestamp = new Date().getTime()) => {
  timestamp = initTime(timestamp);
  const now = new Date(timestamp);
  const currentMonth = now.getMonth();
  const nextMonth = currentMonth + 1;
  const nextMonthFirstDay = new Date(now.getFullYear(), nextMonth, 1).getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const lastDay = new Date(nextMonthFirstDay - oneDay);
  lastDay.setHours(23);
  lastDay.setMinutes(59);
  lastDay.setSeconds(59);
  return lastDay.getTime();
};

/**
 * 获取当前时间上个月的第一天
 */
export const getFirstDayOfPrevMonthByTime = (timestamp) => {
  const firstdate = new Date(new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() - 1, 1);
  return firstdate.getTime();
};
/**
 * 获取当前时间上个月的最后一天
 */
export const getLastDayOfPrevMonthByTime = (timestamp) => {
  const now = new Date(timestamp);
  const lastday = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
  const enddate = new Date(new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() - 1, lastday);
  enddate.setHours(23);
  enddate.setMinutes(59);
  enddate.setSeconds(59);
  return enddate.getTime();
};
/**
 * 获取当前时间所在下个月的第一天
 */
export const getFirstDayOfNextMonthByTime = (timestamp) => {
  const firstdate = new Date(new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() + 1, 1);
  return firstdate.getTime();
};

/**
 * 获取当前时间所在下个月的最后一天
 */
export const getLastDayOfNextMonthByTime = (timestamp) => {
  const now = new Date(timestamp);
  const lastday = new Date(now.getFullYear(), now.getMonth() + 2, 0).getDate();
  const enddate = new Date(new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() + 1, lastday);

  return enddate.getTime();
};

/**
 * 获取几个月前的第一天
 */
export const getRecentMonth = (mon) => {
  mon -= 1;
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  const yearNum = Math.floor(mon / 12);
  const monthNum = mon - yearNum * 12;

  let targetYear = year - yearNum;
  let targetMonth = month - monthNum;
  if (targetMonth <= 0) {
    targetMonth = month + 12 - monthNum;
    --targetYear;
  }
  return getFirstDayOfMonth(targetYear, targetMonth);
};

/**
 * 格式化日期
 */
export const formatDateTimeByStr = (date, pattern) => {
  let result = '';
  try {
    date = moment(date).toDate();
    result = moment(date).format(pattern);
  } catch (e) {
    //
  }
  return result;
};

/**
 * 给定分钟数，计算出时间，包括次日时间
 */
export const calTime = (time) => {
  let hour = Math.floor(time / 60);
  let prefix = '';
  if (hour >= 24) {
    prefix = '次日';
    hour -= 24;
  }
  const minute = time % 60;
  return prefix + moment({ hour, minute }).format('HH:mm');
};
/**
 * 转换数字成星期
 * arr [1,2,3,5,6,7]
 * */

export const formatWeekByNumber = (arr) => {
  if (!arr.length) {
    return '';
  }

  arr.sort(); // 排序

  const splitArr = [];
  let index = 0;

  for (let i = 0; i < arr.length; i++) {
    const curArr = splitArr[index];

    if (!curArr) {
      splitArr[index] = [arr[i]];
      continue;
    }
    if (arr[i] - curArr[curArr.length - 1] === 1) {
      curArr.push(arr[i]);
    } else {
      index++;
      splitArr[index] = [arr[i]];
    }
  }

  return splitArr
    .map((item) => {
      if (item.length === 1) {
        return config[item[0]];
      }
      return `${config[item[0]]}至${config[item[item.length - 1]]}`;
    })
    .join('、');
};

/**
 * 根据开始和结束时间生成时间戳数组
 */
export const getTimeArrFromTimestamps = (start, end, step, excludeEnd = false) => {
  const times = [];
  let current = start;

  while (current <= end) {
    times.push(current);
    current += step;
  }

  excludeEnd && times.pop();

  return times;
};

export const getReserveDateStr = (timestamp) => {
  const mTime = moment(timestamp);
  return ` ${mTime.format('MM-DD')} ${config[mTime.weekday()]} ${mTime.format('HH:mm')}`;
};

export const timeDiff = (source, target) => {
  try {
    return moment(source).diff(moment(target));
  } catch (error) {
    throw new Error('time diff error!');
  }
};

// export const formateTimeByTimeZone = (time, formate = 'YYYY-MM-DD HH:mm:ss') => {
//   if (typeof window === 'undefined') return '';
//   const timestampsAfterTimeZone = momentTimezone.tz
//     .setDefault(window?.global_data?.shopSetting?.timeZone)(time)
//     .format(formate);
//   return timestampsAfterTimeZone;
// };

export const checkIsDST = (time, targetDate, timeZone?) => {
  if (typeof window === 'undefined') return '';
  const globalTimezone = window?.global_data?.shopSetting?.timeZone;
  try {
    /** 哎，心塞，安卓手机的小程序好像不兼容Intl.DateTimeFormat这个方法，用了就报错，再换一种 */
    const options = {
      hour: 'numeric',
      hour12: false,
      timeZone: timeZone || globalTimezone,
    };
    const hour = dayjs(targetDate).hour(); // 不考虑夏令时计算出的hour
    const df = new Intl.DateTimeFormat('en', options);

    if (!isNaN(time)) {
      const dfHour = df.format(time); // 考虑夏令时计算出hour
      // 有些方法获取的hour是24，有些是0，这里判断 hour + Number(dfHour) === 24 也视为相同
      return Number(dfHour) !== hour && hour + Number(dfHour) !== 24; // 不相等表示这个时区下的时间处于夏令时
    }

    return false;
  } catch (err) {
    const hour = dayjs(targetDate).hour(); // 不考虑夏令时计算出的hour
    const dfHour = new Date(
      new Date(time).toLocaleString('en-US', { timeZone: timeZone || globalTimezone }),
    ).getHours();

    // 有些浏览器获取的hour是24小时制，有些是0，这里判断 hour + Number(dfHour) === 24 也视为相同
    return Number(dfHour) !== hour && hour + Number(dfHour) !== 24; // 不相等表示这个时区下的时间处于夏令时
  }
};

// 原有的window对象Intl方法由于兼容多语言被重写，转换时区很多插件是用到了Intl对象的方法，自己写一个，
// 考虑到扩展性，就不用hook封装了，timeZoneDiff的时间字段，需要自己判断globalStore有值的时候再传过来
export const formateTimeByTimeZone = (time, formate = 'YYYY-MM-DD HH:mm:ss', timeZoneDiff?) => {
  if (typeof window === 'undefined') return '';
  const globalTimeZoneDiff = window?.global_data?.shopSetting?.timeZoneDiff;
  if (!timeZoneDiff) {
    timeZoneDiff = globalTimeZoneDiff ?? 0;
  }

  const offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟

  const currentDate = time === undefined ? new Date().getTime() : new Date(time).getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  let targetDate = new Date(currentDate + offset_GMT * 60 * 1000 + timeZoneDiff);

  if (checkIsDST(currentDate, targetDate)) {
    // 如果是夏令时间，需要提前一小时
    targetDate = new Date(currentDate + offset_GMT * 60 * 1000 + timeZoneDiff + HourMsec);
  }

  const formatTime = dayjs(targetDate).format(formate);

  return formatTime;
};

/** 获取店铺时区下，指定时间对应的时间戳,传入指定时间字符串YYYY-MM-DD HH:mm:ss
 * 如：我要获取韩国（+9:00）的2024-04-22 00:00:00 对应的时间戳
 */
 export const getShopTimeStampByDateTime = (time?, timeZoneDiff?) => {
  if (typeof window === 'undefined') return 0;
  const globalTimeZoneDiff = window?.global_data?.shopSetting?.timeZoneDiff;
  if (!time) {
    return new Date().getTime(); // 当前时间的时间戳
  }
  if (!timeZoneDiff) {
    timeZoneDiff = globalTimeZoneDiff ?? 0;
  }
  const offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const currentDate = dayjs(time).valueOf(); // 本地时区下，time对应的时间戳
  let targetDate = currentDate - (timeZoneDiff + offset_GMT * 60 * 1000);

  if (checkIsDST(currentDate, targetDate)) {
    // 夏令时间提前一个小时
    targetDate = currentDate - (timeZoneDiff + offset_GMT * 60 * 1000) - HourMsec; // 减去本地和店铺时区的差值
  }

  return targetDate;
};
